import { format, parseISO } from 'date-fns'

export const formatEventDate = function (startDate, endDate, allDay) {
    const dateFnsFormat = 'MMMM d'
    const startDateFormat = format(parseISO(startDate), dateFnsFormat);
    const endDateFormat = format(parseISO(endDate), dateFnsFormat);

    if(startDateFormat === endDateFormat){
        if(allDay){
            return startDateFormat;
        } else {
            return startDateFormat + '<span>|</span>' + format(parseISO(startDate), 'h:mmbbb') + ' - ' + format(parseISO(endDate), 'h:mmbbb');
        }
    } else {
        return startDateFormat + ' - ' + endDateFormat + '<span>|</span>' + format(parseISO(startDate), 'h:mmbbb') + ' - ' + format(parseISO(endDate), 'h:mmbbb');
    }
}