import dynamic from 'next/dynamic'
import Head from 'next/head'
import Header from '../components/Header';
import LargeCTA from '../components/LargeCTA';
import CTA from '../components/CTA';
import YoastSEO from '../components/YoastSEO';
import { client } from '../lib/apollo';
import { Swiper, SwiperSlide, EffectFade } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import Script from 'next/script'
import Image from "next/legacy/image";
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/effect-fade";
import heroImage from "../public/assets/images/hero-image.webp"
import { formatPostDate } from "../utilities/dateFormat"
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useRef, useCallback} from "react";
import { formatEventDate } from "../utilities/eventDateFormat"

import { GET_HOME, GET_HEADER_OPTIONS, GET_FOOTER_OPTIONS, 
    GET_PAGE_OPTIONS, GET_HOME_BLOG_POSTS, SEARCH, 
    GET_BLOG_FEATURED_IMAGE, GET_HOME_PAGE } from '../queries/queries';


const DynamicFooter = dynamic(() => import('../components/Footer'))

export default function Home({pageData}) {
  const page = pageData?.page?.pageBy?.home;
  const news = pageData?.page?.posts;
  const events = pageData?.page?.events;
  const volunteer = pageData?.page?.eventsCategory?.evt?.edges;
  const headerData = pageData?.headerData;
  const footerData = pageData?.footerData;
  //const pageOptions = pageData?.pageOptions?.data?.pageBy?.pageOptions;
  const cta = pageData?.footerData?.data?.siteOptions?.siteOptions?.cta[0];

  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const eventsSliderRef = useRef(null);

  const eventsHandlePrev = useCallback(() => {
    if (!eventsSliderRef.current) return;
    eventsSliderRef.current.swiper.slidePrev();
  }, []);

  const eventsHandleNext = useCallback(() => {
    if (!eventsSliderRef.current) return;
    eventsSliderRef.current.swiper.slideNext();
  }, []);

  const truncate = function(str) {
    return str.length > 31 ? str.substring(0, 28) + "..." : str;
  }

  return (
      <div>
        <YoastSEO
          MetaRenderElement={Head}
          meta={<>
            <meta name="MobileOptimized" content="width" />
            <meta name="HandheldFriendly" content="True"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0" />
            <title>The Signatry</title>
            <link rel="icon" href="/assets/favicon/favicon.ico"></link>
          </>}
        ></YoastSEO>
        <Header header={headerData}></Header>
        <main>
          <div id="content" role="main">
            <div id="hero-content" className='container'>
              <div dangerouslySetInnerHTML={{ __html: page.heroContent }}></div>
            </div>
            <div id="hero-slider" className='container-fluid'>
              {
                page.heroSlides.length > 0 && (
                  <Swiper
                    ref={sliderRef}
                    modules={EffectFade}
                    loop
                    speed={1000}
                    effect={"fade"}
                    fadeEffect={ {crossFade: true} }
                    slidesPerView={1}
                  >
                    {
                      page.heroSlides.map((item, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <div className='image-wrapper'>
                                <Image
                                    src={`${item.image.sourceUrl}`}
                                    layout='fill'
                                />
                                {
                                  item.caption && (
                                    <div className='hero-slider-image-caption'>
                                       <a data-tooltip-id="fmv-tooltip" data-tooltip-content={`${item.photoCredit}`}>
                                          <img width={24} height={24} src="/assets/images/icon-photo-credit.svg" alt="Tooltip" />
                                      </a>
                                      <ReactTooltip id="fmv-tooltip"></ReactTooltip>
                                    </div>
                                  )
                                }
                            </div>
                            <div className='hero-image-credit container xlarge-container'>
                              <div>
                                {item.caption}
                              </div>
                              <div></div>
                            </div>
                          </SwiperSlide>
                        )
                      })
                    }
                  </Swiper>
                )
              }
            </div>
            <div id="hero-slider-navigation" className='container xlarge-container'>
              <div>
                <div></div>
                <div>
                  <div className="prev-arrow" onClick={handlePrev}>
                      <span className='icon-arrow'></span>
                  </div>
                  <div className="next-arrow" onClick={handleNext}>
                      <span className='icon-arrow'></span>
                  </div>
                </div>
              </div>
            </div>
            <div id="home-widgets-container" className='container xlarge-container'>
              <div id="home-widgets-content" className='container large-container' dangerouslySetInnerHTML={{ __html: page.threeColumnWidgetContent }}></div>
              {
                page.threeColumnWidget && (
                  <div id="home-widgets">
                    {
                      page.threeColumnWidget.map((item, index) => {
                        return (
                          <div className='widget'>
                            <div className='image-wrapper'>
                              <Image
                                  src={`${item.image.sourceUrl}`}
                                  layout='fill'
                              />
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                          </div>
                        )
                      })
                    }
                  </div>
                )
              }
            </div>
            <div id="home-tiles-container" className='container-fluid'>
              <div id="home-tiles-background-image">
                <Image
                    src={`${page.visitBackgroundImage.sourceUrl}`}
                    layout='fill'
                />
              </div>
              <div id="home-tiles-content" className='container xlarge-container'>
                <div>
                  <div>
                    <div dangerouslySetInnerHTML={{ __html: page.visitContent }}></div>
                    {
                      page.visitButtons && (
                        <div id="home-tiles-buttons">
                          {
                            page.visitButtons.map((item,index) => {
                              return (
                                <div key={index} dangerouslySetInnerHTML={{ __html: item.button }}></div>
                              )
                            })
                          }
                        </div>
                      )
                    }
                  </div>
                  <div>
                    {
                      page.visitTiles && page.visitTiles.length > 0 && page.visitTiles.map((item, index) => {
                        return (
                          <a key={index} href={`${item.link}`} className='tile'>
                            <div className='image-wrapper'>
                              <Image
                                  src={`${item.image.sourceUrl}`}
                                  layout='fill'
                              />
                            </div>
                            <div className='tile-content'>
                              <h4>{item.title}</h4>
                            </div>
                          </a>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              {
                page.visitBackgroundImageCaption && (
                  <div id='home-tiles-background-image-caption'>
                      <a data-tooltip-id="fmv-tooltip" data-tooltip-content={`${page.visitBackgroundImageCaption}`}>
                        <img width={24} height={24} src="/assets/images/icon-photo-credit.svg" alt="Tooltip" />
                    </a>
                    <ReactTooltip id="fmv-tooltip"></ReactTooltip>
                  </div>
                )
              }
            </div>
            <div id="home-events-volunteer-container" className='container'>
              <div>
                <div>
                  <div className='image-wrapper'>
                    <Image
                        src={`${page.volunteerImage.sourceUrl}`}
                        layout='fill'
                    />
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: page.volunteerContent }}></div>
                </div>
                <div>
                  <h2>volunteer</h2>
                  {
                    volunteer && (
                      volunteer.map((item, index) => {
                        return (
                          <div className='home-event-item'>
                            <a href={`${item.node.uri}`} className='custom-link'>
                              <h4>
                                {item.node.title}
                              </h4>
                            </a>
                            {
                                item.node.terms.nodes[0].name && (
                                    <div className='event-category'>
                                        {item.node.terms.nodes[0].name}
                                    </div>
                                )
                            }
                            <div className="event-list-details">
                                <p dangerouslySetInnerHTML={{__html: formatEventDate(item.node.startDate, item.node.endDate, item.node.allDay)}}></p>
                            </div>
                          </div>
                        )
                      })
                    )
                  }
                </div>
              </div>
            </div>
            <div id="home-events-container" className='container-fluid'>
              <div className="container">
                <div id='home-events-header'>
                    <div>
                        <h2>{page.upcomingEventsTitle}</h2>
                    </div>
                    <div>
                        <a href="/events/" className='button arrow'>
                            all events 
                            <span className='icon-arrow'></span>
                        </a>
                    </div>
                </div>
                {
                  events.edges.length > 0 && (
                    <Swiper
                      ref={eventsSliderRef}
                      modules={[Pagination]}
                      loop
                      speed={1000}
                      slidesPerView={4}
                      spaceBetween={20}
                      breakpoints={{
                        // when window width is >= 640px
                        1051: {
                          slidesPerView: 4,
                        },
                        800: {
                            slidesPerView: 3,
                        },
                        600: {
                            slidesPerView: 2,
                        },
                        0: {
                            slidesPerView: 1,
                        }
                      }}
                    >
                      {
                        events.edges.map((item, index) => {
                          return (
                            <SwiperSlide key={index}>
                              <div>
                                <div className='image-wrapper'>
                                    <Image
                                        src={`${item.node.featuredImage.node.sourceUrl}`}
                                        layout='fill'
                                    />
                                </div>
                                <div className='event-content'>
                                    <h4>{truncate(item.node.title)}</h4>
                                    {
                                        item.node.terms.nodes[0].name && (
                                            <div className='event-category'>
                                                {item.node.terms.nodes[0].name}
                                            </div>
                                        )
                                    }
                                    <div className="event-list-details">
                                        <p dangerouslySetInnerHTML={{__html: formatEventDate(item.node.startDate, item.node.endDate, item.node.allDay)}}></p>
                                    </div>
                                </div>
                              </div>
                            </SwiperSlide>
                          )
                        })
                      }
                    </Swiper>
                  )
                }
                <div id='home-events-navigation'>
                    <div className="prev-arrow" onClick={eventsHandlePrev}>
                        <span className='icon-arrow'></span>
                    </div>
                    <div className="next-arrow" onClick={eventsHandleNext}>
                        <span className='icon-arrow'></span>
                    </div>
                </div>
                <div id="mobile-home-events-link">
                  <a href="/events/" className='button arrow'>
                      all events 
                      <span className='icon-arrow'></span>
                  </a>
                </div>
              </div>
            </div>
            <div id="home-news-container" className='container'>
              <div>
                <div>
                  <h2>latest news</h2>
                  {
                    news.edges && (
                      news.edges.map((item, index) => {
                        return (
                          <div className='home-news-item'>
                            <h4>
                              {item.node.title}
                            </h4>
                            <a href={`${item.node.uri}`} className='button arrow'>
                              Read Post
                              <span className='icon-arrow'></span>
                            </a>
                          </div>
                        )
                      })
                    )
                  }
                </div>
                <div>
                  <div className='image-wrapper'>
                    <Image
                        src={`${page.newsImage.sourceUrl}`}
                        layout='fill'
                    />
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: page.newsContent }}></div>
                </div>
              </div>
            </div>
            <CTA
              cta={cta}
            ></CTA>
          </div>
        </main>
        <DynamicFooter footer={footerData}></DynamicFooter>
      </div>
  )
}

export async function getStaticProps(params){
  const response = await client.query({
    query: GET_HOME_PAGE,
    variables: { slug: '/' }
  });

  const page = response?.data;

  const headerData = await client.query({
      query: GET_HEADER_OPTIONS
  });

  const footerData = await client.query({
    query: GET_FOOTER_OPTIONS
  });
  
  return {
    props: {
      pageData: {
        page: page,
        footerData: footerData,
        headerData: headerData
      }
    },
    revalidate: 45,
  }
}